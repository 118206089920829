import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Cyber Security Audit" mdxType="SEO" />
    <h1 {...{
      "id": "cyber-security-audit"
    }}>{`Cyber Security Audit`}</h1>
    <hr />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'133\\'%3e%3cpath%20d=\\'M120%2013c-26%206-45%2030-46%2056%200%206-1%207-3%205h-1c-1%201-1%202%202%202l2%202v1l1%204c2%208%208%2020%2015%2027l6%205%201-3%205-2%203-2-5-5c-6-6-12-17-13-26-5-32%2025-59%2056-50%2017%204%2032%2019%2034%2035l3%204v-1c-2-1-1-4%202-5s3-2%203-6c-1-5%201-5%202%200l1%204v-7l-1-4%201%201c0%202%201%201%203-1%200-2%201-2%2013-2l12-1-15-1-15%201%201%201%201%201c-2%202-2%201-4-3-5-11-20-24-33-28-9-3-22-3-31-2m-2%203c-10%202-18%207-26%2014-6%207-14%2019-13%2021v1c-3%204-4%2023-2%2033%203%2011%2011%2024%2013%2022%201-1%201-2-1-3-6-7-11-22-11-33a54%2054%200%200185-46l4%203c5%201%200-3-8-7-12-7-28-9-41-5m8%2018c-8%202-13%2010-13%2019%200%205%200%206-2%206l-5%203c-3%203-3%203-3%2016v14l-1-13c0-12-1-12-1-6l-2%208v1l-2%206-2%204%202%204c3%204%2010%2010%2011%209l3-2%207-1-3-1%2012-1%2020-1h3V80l1-19v-1c-2%200-2-1-2-6%200-15-11-24-23-20m-1%2010c-3%202-3%203-4%209v6h20v-6c0-6-1-8-5-10-4-3-8-2-11%201m3%2028v8c0%208%200%208%203%208s4-2%203-6l1-5v-4c-1-2-6-3-7-1\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Cyber Security audit",
          "title": "Cyber Security audit",
          "src": "/static/e58ea2474736cb43d390a67ff7c21ac6/8537d/cyber-security-audit.png",
          "srcSet": ["/static/e58ea2474736cb43d390a67ff7c21ac6/0fa9c/cyber-security-audit.png 310w", "/static/e58ea2474736cb43d390a67ff7c21ac6/a05ab/cyber-security-audit.png 620w", "/static/e58ea2474736cb43d390a67ff7c21ac6/8537d/cyber-security-audit.png 1200w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The threat from cyber attacks is significant and always changing. Many audit committees and boards have set an expectation for internal audit
to understand and assess the organization’s capabilities in managing the associated risks. Using our experience in the field is an effective first step,
instructing an internal audit to be carried is the first step to conduct a cyber risk assessment.`}</p>
    <br /><br />
    <p>{`Placing the findings into a concise summary for the audit will drive a risk-based, multi year cybersecurity plan.
Our consultants will be happy help conduct cyber security audits in line with industry standard compliance regulations.`}</p>
    <br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      